import dayjs   from "dayjs";
import fromNow from "dayjs/plugin/relativeTime";
import utc     from "dayjs/plugin/utc";

import { LIVESESSION, REEL, VIDEO, _REEL } from "./index";

// use fromNow from dayjs
dayjs.extend ( fromNow );
dayjs.extend ( utc );

export const mapBroadcastSessionProps = params => {
	const { id, sessionInfo, thumbnail, startTimeUTC, broadcaster, liveViews, sessionType, isLeaderBoardLive, playWithFriends } = params;

	const { name, photo, isCeleb } = broadcaster.sportsFan;
	const mediaHref                = `/stream/${ id }`;
	const profileHref              = `/profile/${ broadcaster.sportsFan.id }`;

	return {
		mediaSrc    : thumbnail,
		title       : sessionInfo,
		actorName   : name,
		actorImage  : photo,
		isCeleb,
		timeElapsed : dayjs ( dayjs.utc ( startTimeUTC ) ).fromNow (),
		mediaHref,
		profileHref,
		liveViews,
		sessionType,
		isLeaderBoardLive,
		isPwf       : playWithFriends,
		broadcastId : id
	};
};

export function getVideoFormatTime ( startTime ) {
	const broadcastTime = startTime * 1000;
	const nowTime       = Date.now ();
	let start           = dayjs ( broadcastTime );
	let end             = dayjs ( nowTime );
	let time            = dayjs ( end.diff ( start ) );

	time = time.subtract ( 5, "hours" );
	time = time.subtract ( 30, "minutes" );
	time = time.format ( "HH:mm:ss" );
	if ( time.charAt ( 0 ) === "0" && time.charAt ( 1 ) === "0" ) {
		time = time.substr ( 3 );
	}

	return time;
}

export const getFormattedCountdown = secs => {
	var hours   = Math.floor ( secs / 3600 );
	var minutes = Math.floor ( ( secs - ( hours * 3600 ) ) / 60 );
	var seconds = secs - ( hours * 3600 ) - ( minutes * 60 );

	if ( hours < 10 ) {
		if ( hours == 0 ) hours = "";
		else hours = "0" + hours;
	}

	if ( minutes < 10 ) {
		if ( !hours && minutes == 0 ) minutes = "";
		else minutes = "0" + minutes;
	}

	if ( seconds < 10 ) {
		seconds = "0" + seconds;
	}

	if ( !hours && !minutes ) return seconds + "s";
	if ( !hours && minutes ) return minutes + ":" + seconds;

	return hours + ":" + minutes + ":" + seconds;
};

export const formatLiveSession = item => {
	return {
		id         : item?.id,
		createdAt  : item?.startTimeUTC,
		endedAt    : item?.endTimeUTC,
		streamTier : item?.streamTier || "0",
		title      : item?.sessionInfo,
		isLive     : !item?.endTimeUTC,
		userData   : {
			id          : item?.broadcaster?.sportsFan?.id,
			name        : item?.broadcaster?.sportsFan?.name,
			photo       : item?.broadcaster?.sportsFan?.photo,
			isCeleb     : item?.broadcaster?.sportsFan?.isCeleb,
			isFollowing : item?.broadcaster?.sportsFan?.isFollowing
		},
		midTierRemainingAdWatchCount : item?.midTierRemainingAdWatchCount || null,
		thumbnail                    : item?.thumbnail,
		src                          : item?.cdnUrl,
		feedType                     : LIVESESSION,
		views                        : !item?.endTimeUTC ? item?.liveViews : item?.views, // totalViews, views are already there, not confirm which one should be used
		// game      : item?.game
		reactions                    : item?.totalReactions || 0,
		commentsCount                : item?.totalComments || 0,
		sharesCount                  : item?.totalShares || 0
	};
};

export const formatVideo = item => {

	return {
		id        : item?.id,
		createdAt : item?.created_at,
		title     : item?.title,
		userData  : {
			id          : item?.actor_details_v2?.id,
			name        : item?.actor_details_v2?.name,
			photo       : item?.actor_details_v2?.photo,
			isCeleb     : item?.actor_details_v2?.isCeleb,
			isFollowing : item?.actor_details_v2?.isFollowing
		},
		thumbnail     : item?.media?.find ( el => el?.media_sequence === "2" )?.href || "", // extract from image in media fragment
		src           : item?.media?.find ( el => el?.media_sequence === "3" )?.href || "", // extract from video in media fragment
		videoDuration : item?.read_time,
		views         : item?.views_count, // totalViews, views are already there, not confirm which one should be used
		feedType      : VIDEO,
		sharesCount   : item?.shares_count || 0,
		reactions     : item?.reactions?.find ( el => el.reaction === "AGREE" )?.reaction_count || 0,
		commentsCount : item?.comment_count || 0
	};
};

export const formatReel = item => {

	return {
		id        : item?.id,
		createdAt : item?.createdAt,
		title     : item?.title,
		userData  : {
			id          : item?.actorDetails?.id,
			name        : item?.actorDetails?.name,
			photo       : item?.actorDetails?.photo,
			isCeleb     : item?.actorDetails?.isCeleb || null,
			isFollowing : item?.actorDetails?.isFollowing || null
		},
		thumbnail     : item?.media?.find ( el => el?.mediaSequence === "2" )?.href || "", // extract from image in media fragment
		src           : item?.media?.find ( el => el?.mediaSequence === "3" )?.href || "", // extract from video in media fragment
		videoDuration : item?.read_time,
		views         : item?.views, // totalViews, views are already there, not confirm which one should be used
		feedType      : REEL,
		sharesCount   : item?.sharesCount || 0,
		reactions     : item?.reactions?.find ( el => el.reaction === "AGREE" )?.reaction_count || 0,
		commentsCount : item?.commentCount || 0
	};
};

export const formatPersonalizedFeedData = ( list = [] ) => {

	const formattedList = [];

	list.forEach ( item => {
		if ( item?.typeName === LIVESESSION ) {
			formattedList.push ( formatLiveSession ( item ) );
		} else if ( item?.typeName === VIDEO || item?.typeName === REEL ) {
			formattedList.push ( formatVideo ( item ) );
		} else if ( item?.parent_post_type?.toUpperCase () === _REEL ) {
			formattedList.push ( formatReel ( item ) );
		}
	} );

	return formattedList;
};
