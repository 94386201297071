import dynamic                 from "next/dynamic";
import { useEffect, useState } from "react";

import { getAllCategories, getProductsByCategory } from "_services/api/shop";

const Desktop = dynamic ( () => import ( "./desktop" ) );
const Mobile  = dynamic ( () => import ( "./mobile" ) );

const HomePage = props => {
	const {
		isMobile
	} = props;

	const [ shopData, setShopData ] = useState ( null );
	const [ loading, setLoading ]   = useState ( false );

	async function fetchProducts () {
		try {
			setLoading ( true );
			const categories        = await getAllCategories ();
			const categoriesToFetch = categories.map ( v => v.name );

			const res = await getProductsByCategory ( {
				categoryNames : categoriesToFetch,
				pageSize      : 10,
				pageNo        : 1
			} );

			setShopData ( res.items.slice ( 0, 6 ) );
		} catch ( error ) {
			// console.log ( "========  error:", error );
		}
		setLoading ( false );
	}

	useEffect ( () => {
		fetchProducts ();
	}, [] );

	if ( isMobile )
		return (
			<Mobile
				{ ...props }
				loading  = { loading }
				shopData = { shopData }
			/>
		);

	return (
		<Desktop
			loading  = { loading }
			shopData = { shopData }
			{ ...props }
		/>
	);

};

export default HomePage;
