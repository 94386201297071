import xhr         from "_common/xhr";
import { configs } from "_config/index";

export async function getProductsByCategory ( { categoryNames = [], pageSize, pageNo, newFilters = {}, userId } ) {
	const result = await xhr.post.bind ( this ) ( {
		url  : `${ configs.SHOP_URL }listings/productByCat`,
		data : {
			categoryNames : categoryNames?.join (),
			pageSize,
			pageNo,
			userId        : parseInt ( userId ),
			...newFilters
		}
	} );

	return result;
}

export async function getAllCategories () {
	const result = await xhr.get.bind ( this ) ( {
		url: `${ configs.SHOP_URL }listings/categories`
	} );

	return result;
}
